import logo from './assets/logo.png'
import './App.css';
import UncontrolledExample from './courousel';
import Header from './Header';
import Announcements from './Announcements';
import HomePageContents from './HomePageContents';
import Footer from './footer';


const Home = () => {
    return ( 
       <>
        <Announcements/>
        <Header/>
        <Carousel/>
        <HomePageContents/>
        <Footer/>
       </>
     );
}





const Carousel = ()=>{
   return ( <div
    style={{
        height:'500px',
        // border:'1px solid red',
        position:'relative'
    }} 
>
    {/* hello */}
<UncontrolledExample/>
</div>);
}
 
export default Home;