import bottle from './assets/others/icons/bottle-alcohol-wine-svgrepo-com.png';
import juiceCan from './assets/others/icons/juice-svgrepo-com.png';
import Candies from './assets/others/icons/three-halloween-candies-svgrepo-com.png';
import NdumunyarwandaPackGrand from './assets/images/grand-ndumunyarwanda.png';
import InkororaGrand from './assets/images/inkorora-pack.png';
import GorillaPowerGinImageGroup from './assets/images/yego_18.JPG';
import GorillaCoconutImageGroup from './assets/images/yego_18.JPG';
// import LionVodkaImageGroup from './assets/images/yego_18.JPG';
import LeyonVodkaImageGroup from './assets/images/yego_15.JPG';
import ProductLionWhite from './assets/products-images/yego new product_lion_white_.jpg';
import ProductLionBlack from './assets/products-images/yego new product_lion_black_.jpg';
import tuple from './assets/images/tupple.jpg';
import marie from './assets/images/team-3.jpg';
import CountUpComponent from './CountUp';
import { motion, useMotionValueEvent, useScroll } from "framer-motion";
import { Canvas,ambientLight } from '@react-three/fiber';
import { AmbientLight } from 'three';
import { Suspense } from 'react';
import Model from './assets/others/3d/Lion_small';
import { OrbitControls } from '@react-three/drei';

const HomePageContents = () => {
    const { scrollYProgress} = useScroll();
    useMotionValueEvent(scrollYProgress, "change", (latest)=>{
        // console.log(latest);
    })
    return ( 
    <motion.div
    
    >
    <motion.section 
  

    // style={{
    //     scaleX: 1 / scrollYProgress,
    // }}
    className="content-1">
        {/* <Canvas camera={{ position: [0, 0, 20], fov: 475 }}
        style={{height:"300px",
        //  border:'2px solid red',
          width:'50vw',margin:'auto'}}
          >
            <ambientLight 
                intensity={3}
            />
            <OrbitControls/>
            <Suspense fallback={null}>
                <Model/>
            </Suspense>
        </Canvas> */}
        <div className="centered-div">
            <div><h4>Made in Rwanda Products</h4></div> 
           
            <div> <span className='header-title'>Premium Liquor, Refreshing Juices, and Delightful Candies for Every Occasion!</span></div>
        </div> 
    </motion.section>
    {/* <motion.section 
      animate={{ scale: 2 }} 
      transition={{ duration: 1, ease: "easeInOut" }} // Optional transition for smooth scaling
      className="content-1"
      style={{ width: '100px', height: '100px', backgroundColor: 'lightblue' }} // Adding some styles for better visualization
    >
      hello
    </motion.section> */}
    <motion.section 
    // variants={{
    //     hidden:{scale:2},
    //     show:{scale:1},
    //     transition: { duration: 0.5, ease: "easeInOut" } 
    // }} 
    className="content-1 cards flex-1">
        <div className="card box-shadow">
            <div className="card-icon-holder">
                <img src={bottle}/>
            </div>
            <div className="card-info">
            Discover Yego's exquisite liquors, blending Rwandan tradition with modern craftsmanship. Each bottle offers a unique and memorable drinking experience, celebrating quality and excellence in every sip.
            </div>
        </div>
        <div className="card ">
        <div className="card-icon-holder">
        <img src={juiceCan}/>
            </div>
            <div className="card-info">
            Refresh with Yego's all-natural juices, made from Rwanda's finest fruits. Enjoy the vibrant, pure flavors that bring a taste of our beautiful country to every glass.
                </div>
            </div>
        <div className="card box-shadow">
        <div className="card-icon-holder">
        <img src={Candies}/>
            </div>
        <div className="card-info">
        Indulge in Yego's premium candies, crafted from the finest ingredients. Our delightful treats offer a burst of joy and sweetness, perfect for any moment you need a little indulgence.
            </div>
        </div>
    </motion.section>
    <section className="content-1 column-2 clipped">
        <div className=' column-50'>
            <h6>Candies</h6>
            <h1>
            <span className='header-title'>Our Renowned Ndumunyarwanda & Inkorora Candies</span>
            </h1>
            <br/>
            <h4>Ndumunyarwanda Candies</h4>
            <p>
            Enjoy the delightful taste of Ndumunyarwanda Candies, a celebrated treat that brings joy to every moment. Crafted with care, these candies are perfect for satisfying your sweet cravings while enjoying the essence of Rwandan tradition.
            </p>
            <h5>Ingredients:</h5>
            <ul className='candies-list'>
                <li>Chocolate</li>
                <li>Glucose Syrup</li>
                <li>Modified Corn Starch</li>
                <li>Citric Acid</li>
                <li>Natural and Artificial Flavours</li>
            </ul>
            <br/>
  
            <h4>INKORORA Candies</h4>
            <p>
            Experience the unique and irresistible flavor of Inkorora Candies. These treats are a perfect blend of sweetness and quality, making them a favorite choice for candy lovers of all ages.
            </p>
            <h5>Ingredients:</h5>
            <ul className='candies-list'>
                <li>Sugar</li>
                <li>Citric Acid</li>
                <li>Tartaric Acid</li>
                <li>Sodium Citrate</li>
                <li>Colour (with Tartrazine)</li>
            </ul>
        </div>
        <div className='column-50 candies'>
        <img className='image-style-1' src={InkororaGrand}/>
        <img className='image-style-2' src={NdumunyarwandaPackGrand}/>
        </div>
    </section>
    <section className="content-1">
        <div className="centered-div">
            <div className='text-shadow'>Juices</div>
            <div className='text-shadow'>Refreshing Juices from Rwanda's Finest Fruits</div>
        </div>
    </section>
    <section className="content-1 cards flex-1"> 
        <div className="card-2">
            <div className="card-icon-holder">
            <img src={juiceCan}/>
            </div>
            <div className="card-info flex-2" style={{alignItems:'start'}}>
                <h5>Mango Fresh Juice</h5>
                {/* <h5>Ingredients:</h5> */}
            <ul className='juice-list'>
                <li>Mango Bliss</li>
                <li>Pure Fruit Juice</li>
                <li>Water</li>
                <li>Natural Flavors</li>
                <li>Vitamin C</li>
            </ul>
            </div>
        </div>
        <div className="card-2">
        <div className="card-icon-holder">
        <img src={juiceCan}/>
            </div>
            <div className="card-info flex-2" style={{alignItems:'start'}}>
                <h5>Apple Fresh Juice</h5>
                {/* <h5>Ingredients:</h5> */}
            <ul className='juice-list'>
                <li>100% Pure Apple Juice</li>
                <li>Water</li>
                <li>Vitamin C (Ascorbic Acid)</li>
                <li>Citric Acid (for tartness)</li>
            </ul>
            </div>
        </div>
        {/* <div className="card-2">
        <div className="card-icon-holder">
        <img src={Candies}/>
            </div>
        <div className="card-info">
        Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
            </div>
        </div> */}
    </section>
    <section className="content-1">
        <div className="centered-div">
            <div>Liquors</div>
            <div>Exquisite Rwandan Liquors</div>
        </div>
        
    </section>

    <section className='content-1 basic-grid-1'>
        <div className='flex-2  flex-center-both'>
        {/* <img className='image-2' src={bottle}/> */}
        <img className='image-2'  src={GorillaPowerGinImageGroup} alt="Gorilla Power Gin"/>
        </div>
        <div className='flex-2 flex-center-horizontal'>
        <div><span className='header-title-2'>Gorilla Gin</span></div>
        <div>Indulge in the smooth and sophisticated flavor of Gorilla Gin. Crafted with precision, this gin offers a harmonious blend of distilled spirits and distinctive gin flavors, making it the perfect choice for any gin enthusiast.</div>
        <div><div className="card-info flex-2" style={{alignItems:'start'}}>
                <h5>Ingredients</h5>
                {/* <h5>Ingredients:</h5> */}
            <ul className='juice-list'>
                <li>Distilled Water</li>
                <li>Distilled Spirits</li>
                <li>Gin Flavour</li>
                <li>42% volts</li>
            </ul>
            </div></div>
        </div>
    </section>

    {/* <section className='content-1 basic-grid-1'>
        <div className='flex-2  flex-center-both'>
        <img className='image-2'  src={GorillaCoconutImageGroup}/>
        </div>
        <div className='flex-2 flex-center-horizontal'>
        <div><span className='header-title-2'>Gorilla Coconut Gin</span></div>
        <div>Discover the unique and exotic taste of Gorilla Coconut Gin. This delightful gin combines the classic gin essence with a refreshing coconut twist, creating an unforgettable tropical experience.</div>
        <div><div className="card-info flex-2" style={{alignItems:'start'}}>
        <h5>Ingredients</h5>
            <ul className='juice-list'>
                <li>Distilled Water</li>
                <li>Distilled Spirits</li>
                <li>Gin Flavour</li>
                <li>Coconut Flavour</li>
                <li>42% volts</li>
            </ul>
            </div></div>
        </div>
    </section> */}
    <section className='content-1 basic-grid-1'>
        <div className='flex-2  flex-center-both'>
        <img className='image-2'  src={LeyonVodkaImageGroup} alt='Leyon Vodka'/>
        </div>
        <div className='flex-2 flex-center-horizontal'>
        <div><span className='header-title-2'>Leyon Vodka</span></div>
        <div>Discover the unique and exotic taste of Gorilla Coconut Gin. This delightful gin combines the classic gin essence with a refreshing coconut twist, creating an unforgettable tropical experience.</div>
        <div><div className="card-info flex-2" style={{alignItems:'start'}}>
        <h5>Ingredients</h5>
            <ul className='juice-list'>
                <li>Distilled Water</li>
                <li>Distilled Spirits</li>
                <li>Vodka Flavour</li>
                <li>42% volts</li>
            </ul>
            </div></div>
        </div>
    </section>
    
    
   
  
    <section className="content-1 ">
        <div className="centered-div">
            <div>New products</div>
            <div>Introducing Lion Hearts</div>
        </div>
    </section>
    <section className="content-1 column-2 ">
        <div className=' column-50'>
  
            <h1>
            <span className='header-title-4'>Lion Heart Waragi</span>
            </h1>
            <br/>
            <p>Embrace the rich and robust taste of Lion Heart Waragi. This black waragi is a masterful blend of distilled spirits and unique waragi flavors, offering a bold and authentic Rwandan drinking experience.</p>
            <h5>Ingredients</h5>
                {/* <h5>Ingredients:</h5> */}
            <ul className='juice-list'>
                <li>Distilled Water</li>
                <li>Distilled Spirits</li>
                <li>Waragi Flavours</li>
                <li>42% volts</li>
            </ul>
        </div>
        <div className='column-50 candies'>
        <img className='image-style-3' src={ProductLionWhite}/>
        </div>
    </section>
    <section className="content-1 column-2 ">
       
        <div className='column-50 candies'>
        <img className='image-style-3' src={ProductLionBlack}/>
        </div>

        <div className=' column-50'>
    
            <h1>
            <span className='header-title-4'>We Are In A Mission To Help Helpless</span>
            </h1>
            <br/>
            <p>Discover the smooth and refreshing flavor of Lion Heart White Rum. This premium white rum is crafted from the finest distilled spirits and blended with subtle rum flavors, making it the perfect choice for any rum lover.</p>
            <h5>Ingredients</h5>
                {/* <h5>Ingredients:</h5> */}
            <ul className='juice-list'>
                <li>Distilled Water</li>
                <li>Distilled Spirits</li>
                <li>Rum Flavour</li>
                <li>42% volts</li>
            </ul>
        </div>
    </section>
    <section className="content-1">
        <div className="centered-div">
            <div>Testimonials</div>
        </div>
    </section>
   
    <section className='content-1 flex-1 testimonials'>
        <div className=''>
            <div>
                <h1 style={{color:'#4a5a67'}}>Beverage Connoisseur</h1>
            </div>
            <div>
            “Yego Manufacturers has truly exceeded my expectations with their products. The quality of their liquors, especially the Gorilla Gin and Leyon Vodka, is exceptional and stands out in the market. Every sip reflects their dedication to craftsmanship and the rich Rwandan heritage. I highly recommend Yego for anyone seeking top-notch beverages.”
            </div>
            <div className='testimonial-info'>
                <img src={marie}/>
                <div>
                    <span>— Marie N.</span>
                    <span>CEO of Beverage Connoisseur</span>
                </div>
            </div>
        </div>
        <div className=''>
            <div>
                <h1 style={{color:'#4a5a67'}}>Customer</h1>
            </div>
            <div>
            “I've been consistently impressed with Yego's range of juices and candies. Their Apple Juice is refreshingly pure, and the Ndumunyarwanda Candies are a delightful treat. Yego's commitment to quality and natural ingredients is evident in every product. It's clear they put a lot of care into everything they make.”
            </div>
            <div className='testimonial-info'>
                <img src={tuple}/>
                <div>
                    <span>— David R.</span>
                    <span>Loyal Customer</span>
                </div>
            </div>
        </div>

    </section>
    {/* <section className='content-1'>
        upcomming events
    </section> */}
    <section className=''>
       <CountUpComponent/>
    </section>
    </motion.div> 
    );
}
 
export default HomePageContents;