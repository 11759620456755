import './App.css';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';

const Announcements = () => {
    return (
        <div className='announcements'>
            <div>Contact us now!</div>
            <div><LocalPhoneIcon/> +250 787 750 621 </div>
            <div><EmailIcon/> yegomanufactures@gmail.com</div>
        </div>
    );
}
 
export default Announcements;