import logo from './assets/logo.png'
import './App.css';
import { Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';



const Header = () => {
    const showSidebar = ()=>{
        // alert('hello sidebar')
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'flex';
    }
    const hideSidebar = ()=>{
        // alert('hello sidebar')
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'none';
    }
    return ( 
        <div className="header">
        <div className="navbar" style={{padding:'10px 40px'}}>
        <div className="logo-holder">
                <img className='logo' src={logo}/>
                <div className='logo-title'><span className='logo-1'>Yego</span><br/> <span className='logo-2'>Manufacturers</span></div>
            </div>
            
            <div className="nav-list">
            <Link className='onMobile' to = "/"><a href="">HOME</a></Link>
            <Link className='onMobile' to = "/about"><a href="">ABOUT US</a></Link>
            <Link className='onMobile' to = "/products"><a href="">PRODUCTS</a></Link>
            {/* <Link className='onMobile' to = "/services"><a href="">SERVICES</a></Link> */}
            <Link className='onMobile' to = "/news"><a href="">NEWS</a></Link>
            <Link className='onMobile' to = "/contact"><a href="">CONTACTS</a></Link>
            <Link className='onLargeScreen' to = "#"><a href="" onClick={()=>{showSidebar()}}><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#5f6368"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/></svg></a></Link>
            <Paper
             sx={{boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}
             elevation={2} className='search-box'><input type='text' placeholder='Search product'/><button>Search</button></Paper>
        </div>
            <div className="nav-list sidebar">
            <Link  to = "#"><a onClick={()=>{hideSidebar()}} href="#"><svg xmlns="http://www.w3.org/2000/svg" height="26px" viewBox="0 -960 960 960" width="26px" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg></a></Link>
            <Link  to = "/"><a href="">HOME</a></Link>
            <Link  to = "/about"><a href="">ABOUT US</a></Link>
            <Link  to = "/products"><a href="">PRODUCTS</a></Link>
            {/* <Link to = "/services"><a href="">SERVICES</a></Link> */}
            <Link to = "/news"><a href="">NEWS</a></Link>
            <Link to = "/contact"><a href="">CONTACTS</a></Link>
            <Paper
             sx={{boxShadow:'2px 2px 5px rgba(0, 0, 0, 0.3)'}}
             elevation={2} className='search-box'><input type='text' placeholder='Search product'/><button>Search</button></Paper>
        </div>
        </div>
    </div>
     );
}
 
export default Header;