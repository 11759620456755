import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function BasicExample() {
  return (
    <>
    <form className='contact-form'>
        <input placeholder='YOUR NAME...'/>
        <input placeholder='EMAIL ADRESS...'/>
        <input placeholder='PHONE NUMBER...'/>
        <textarea placeholder='MESSAGE...' rows="4" />
        <input type='submit' value="SEND"/>
    </form>
    </>
  );
}

export default BasicExample;