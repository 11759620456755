/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.4.1 lion_small.glb 
*/

import React, { useRef } from 'react'
import { useGLTF, MeshTransmissionMaterial } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import { useControls } from 'leva';

export default function Model(props) {
  const groupRef = useRef();

  useFrame(() => {
    if (groupRef.current) {
      // groupRef.current.rotation.y += 0.01; // Rotate model around y-axis
      groupRef.current.rotation.x += 0.01;
      groupRef.current.rotation.y += 0.01;
    }
  });

  const materialProps = useControls({
    thickness:{value: 0.2, min:0, max:3, step:0.05},
    roughness:{value: 0, min:0, max:1, step:0.1},
    ior:{value: 1.2, min:0, max:1, step:0.1},
    chromaticAberration:{value: 1.2, min:0, max:1},
    backside:{value: true},
  });

  const { nodes, materials } = useGLTF('Lion_small.glb')
  return (
    <group {...props} dispose={null} position={[0, 0, 0]} scale={[4, 4, 4]} ref={groupRef}>
      <mesh geometry={nodes.bottle.geometry} material={materials['Material.001']} position={[-0.558, 0.42, 1.385]} scale={[1, 0.662, 0.709]} >
        <MeshTransmissionMaterial {...materialProps}/>
      </mesh>
      <mesh geometry={nodes.cup.geometry} material={materials.cup_material} position={[-0.56, 0.815, 1.411]} scale={[1, 0.662, 0.709]} />
      <mesh geometry={nodes.cup_lebel.geometry} material={materials.cup_material} position={[-0.56, 0.815, 1.411]} scale={[1, 0.662, 0.709]} />
      <mesh geometry={nodes.Plane.geometry} material={materials.cup_material} position={[-0.558, -0.893, 1.989]} rotation={[Math.PI / 2, 0, 0]} />
    </group>
  )
}

useGLTF.preload('Lion_small.glb')
