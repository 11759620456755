import Carousel from 'react-bootstrap/Carousel';
import yego_0 from './assets/images/yego_0.JPG'; 
import yego_1 from './assets/images/yego_5.JPG'; 
import yego_2 from './assets/images/yego_15.JPG'; 
// import yego_3 from './assets/images/yego_3.JPG'; 
import { motion } from 'framer-motion';

function UncontrolledExample() {
  return (
    <Carousel>
      <Carousel.Item>
        <motion.img
        from={{
          opacity:0,
        }}
        animate={{
          opacity:1,
        }}
        // style={{
        //   objectFit:'contain'
        // }}
        className='header-image' src={yego_0}
        />
        <Carousel.Caption>
          <h3>Automatic Labeling</h3>
          <p>Bottles move along a conveyor belt, passing through a labeling machine that applies labels quickly and accurately.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <motion.img className='header-image' src={yego_1}/>
        <Carousel.Caption>
          <h3>Automatic Capping</h3>
          <p>Bottles move along a conveyor belt to a capping machine that automatically places and secures the caps</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img className='header-image' src={yego_2}/>
        <Carousel.Caption>
          <h3>Branding and Packaging</h3>
          <p>
          The bottle design is sleek and elegant, reflecting the premium nature of the product.
          Labels are designed to convey the brand's identity, featuring the Leyon Vodka logo and essential product information.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;